import ambiente1 from './recebeAmbiente1.jpg'
import ambiente2 from './recebeAmbiente2.jpg'
import ambiente3 from './recebeAmbiente3.jpg'
import ambiente4 from './recebeAmbiente4.jpg'
import pizza1 from './recebePizza1.jpg'
import pizza2 from './recebePizza2.jpg'
import pizza3 from './recebePizza3.jpg'
import pizza4 from './recebePizza4.jpg'
import pizza5 from './recebePizza5.jpg'
import pizza6 from './recebePizza6.jpg'
import pizza7 from './recebePizza7.jpg'
import pizza8 from './recebePizza8.jpg'
import acompanhamento1 from './recebeAcompanhamento1.jpg'
import acompanhamento2 from './recebeAcompanhamento2.jpg'
import acompanhamento3 from './recebeAcompanhamento3.jpg'
import acompanhamento4 from './recebeAcompanhamento4.jpg'




const recebeEspaco = [ambiente1, ambiente2, ambiente3, ambiente4]
const recebePizzas = [pizza1, pizza2, pizza3, pizza4, pizza5, pizza6, pizza7, pizza8]
const recebeAcompanhamentos = [acompanhamento1, acompanhamento2, acompanhamento3, acompanhamento4]

export { recebeEspaco, recebePizzas, recebeAcompanhamentos }