import lfHero from '../assets/lf-cover-menu.png'

const Section = (props) => {
  return (
    <>
      <p className="uppercase play font-extrabold pt-4 text-2xl">
        {props.title}
      </p>
      {props.items.map((item, index) => (
        <div key={index} className="pt-3">
          <p className="josefin">{item.name}</p>
          {item.info ? (
            <p className="josefin italic font-light">{item.info}</p>
          ) : null}
        </div>
      ))}
    </>
  )
}

const photoIcon = <i class="bi bi-camera-fill"></i>

export function Recebe() {
  return (
    <>
      <section class="w-full h-screen">
        <img
          className="object-cover w-full h-full"
          src={lfHero}
          alt="hero"
        ></img>
      </section>
      <div className="py-20 px-5 play max-w-2xl mx-auto text-xl">
        <h1 className="josefin text-5xl leading-tight font-bold text-red-700 uppercase">
          La Farina RECEBE!
        </h1>
        <p className="pt-4">
          Tudo começou há alguns anos, meio que de brincadeira.
        </p>
        <p className="pt-4">"Vamos fazer uma pizza?"</p>
        <p className="pt-4">
          Como você pode imaginar, a ideia evoluiu. Passamos dias experimentando
          receitas, estudando técnicas de fermentação, e assando muitas pizzas.
          Em 2019, visitamos Nápoles, na Itália, para provar a nossa pizza
          favorita - <b>a pizza napolitana</b>.
        </p>
        <p className="pt-4">
          Hoje, abrimos a nossa casa e convidamos você para viver um pouco dessa
          experiência. Traga os amigos, um bom vinho, e venha curtir um menu
          degustação de pizzas montado com muito carinho.
        </p>
        <p className="pt-4">
          Tudo isso em um ambiente bem aconchegante, sem cerimônias, estilo casa
          de avó. Ah, e venha com fome!{' '}
        </p>
        <div className="text-right pt-4">- Rita & Eugênio</div>

        <h1 className="josefin pt-24 pb-6 text-3xl leading-tight font-bold text-red-700 uppercase">
          MENU DEGUSTAÇÃO DE PIZZAS
        </h1>

        <Section
          title="Entradas"
          items={[{ name: 'CROSTINI', info: <>caponata de beringela <br /> tomate seco</> }]}
        ></Section>

        <Section
          title="Pizzas"
          // prettier-ignore
          items={[
            { name: 'PARMA', info: 'molho de tomate, mussarela, presunto de parma, tomate seco, rúcula e grana padano' },
            { name: 'BIANCA', info: 'molho de alho, mussarela, alho poró, bacon e orégano' },
            { name: 'MARGUERITA', info: 'molho de tomate, mussarela de búfala, manjericão', },
            { name: 'PEPPERONI', info: 'molho de tomate, mussarela, pepperoni, manjericão e tomates confitados' },
            { name: 'CAESAR', info: 'molho caesar, mussarela, frango defumado, alface americana e tomate seco' },
          ]}
        ></Section>

        <Section
          title="Acompanhamentos"
          items={[
            { name: 'Creme de alho' },
            { name: 'Geléia de pimenta' },
            { name: 'Pesto de manjericão' },
          ]}
        ></Section>

        <Section
          title="SOBREMESA"
          items={[{ name: 'PANNA COTTA', info: 'frutas' }]}
        ></Section>

        <h1 className="josefin pt-24 pb-12 text-3xl leading-tight font-bold text-red-700 uppercase">
          FAÇA sua reserva
        </h1>

        <p className="text-center font-bold text-4xl">R$ 98,00 por pessoa</p>
        <p className="text-center text-2xl pt-2 josefin">
          Inclui entradas, pizzas, acompanhamentos e sobremesa.
        </p>
        <div className="text-center pt-12 josefin">
          <p className="">Reserve já:</p>
          <a
            href="https://wa.me/5527999430406"
            className="btn btn-lg btn-whatsapp mt-3 mr-3 shadow py-2 rounded-0"
          >
            WhatsApp
          </a>
          <a
            href="https://instagram.com/lafarina.massas"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-lg btn-instagram mt-3 mr-3 shadow rounded-0"
          >
            <div className="flex items-center">Instagram</div>
          </a>
          <a
            href="/recebe-fotos"
            target={'_blank'}
            className="btn btn-outline-dark btn-lg josefin shadow rounded-0 mt-3 mr-3"
          >
            {photoIcon} Fotos
          </a>
        </div>
        <p className="font-bold uppercase pt-20 josefin">Observações</p>
        <p className="pt-4 josefin">
          1. Devido ao tempo de fermentação das pizzas, pedimos que as reservas
          sejam feitas com <b>4 dias</b> de antecedência.
        </p>
        <p className="pt-2 josefin">
          2. As reservas serão para grupos de 5 a 14 adultos.
        </p>
        <p className="pt-2 josefin">
          3. Nosso atendimento acontece habitualmente a partir das 19h.
        </p>
        <p className="pt-2 josefin">
          4. Bebidas são cobradas separadamente. Não cobramos serviço de rolha.
        </p>
        <p className="pt-2 josefin">
          5. Aceitamos pagamento em cartões e Pix. Não cobramos taxa de serviço.
        </p>
        <p className="pt-2 josefin">6. Valor para crianças: 0-5 anos não pagam. 6-10 anos R$32,00. 11-12 anos pagam meia.</p>
        <p className="pt-2 josefin">
          7. A quantidade de pizzas pode variar de acordo com o número de pessoas.
        </p>
      </div>
    </>
  )
}
