import { recebeEspaco, recebePizzas, recebeAcompanhamentos } from '../assets/recebeAssets'

export function RecebeFotos() {

  return (
    <>
      <div className="mt-2 py-6 max-w-2xl mx-auto text-xl text-center">
        <h1 className="josefin text-5xl leading-tight font-bold text-red-700 uppercase">
          Esse é nosso espaço!
        </h1>
      </div>
      <div className="mx-10 row">
        {recebeEspaco.map((item) => (
          <div className="col-12 col-md-3 mb-3" style={{ height: 350 }}>
            <img
              className="recebe-foto shadow rounded"
              src={item}
              alt="recebe"
            ></img>
          </div>
        ))}
      </div>
      <div className="mt-5 py-6 max-w-2xl mx-auto text-xl text-center">
        <h1 className="josefin text-5xl leading-tight font-bold text-red-700 uppercase">
          Conheça nossas pizzas!
        </h1>
      </div>
      <div className="mx-10 mb-10 row">
        {recebePizzas.map((item) => (
          <div className="col-12 col-md-3 mb-3" style={{ height: 350 }}>
            <img
              className="recebe-foto shadow rounded"
              src={item}
              alt="recebe"
            ></img>
          </div>
        ))}
      </div>
      <div className="mt-5 py-6 max-w-2xl mx-auto text-center">
        <h1 className="josefin text-3xl md:text-5xl leading-tight font-bold text-red-700 uppercase">
          Conheça nossos acompanhamentos!
        </h1>
      </div>
      <div className="mx-10 mb-10 row">
        {recebeAcompanhamentos.map((item) => (
          <div className="col-12 col-md-3 mb-3" style={{ height: 350 }}>
            <img
              className="recebe-foto shadow rounded"
              src={item}
              alt="recebe"
            ></img>
          </div>
        ))}
      </div>
    </>
  )

}
