export const cardapio = [
  {
    name: 'Tradicionais',
    items: [
      {
        name: 'Spaguetti',
        size: '250g, 500g, 1kg',
        description:
          'Massa fresca tradicional tipo spaguetti, com trigo e ovos caipiras',
        price: '36 / kg',
        picture: 'spaguetti.jpg',
      },
      {
        name: 'Talharim',
        size: '250g, 500g, 1kg',
        description:
          'Massa fresca tradicional tipo talharim, com trigo e ovos caipiras',
        price: '36 / kg',
        picture: 'talharim.jpg',
      },
      {
        name: 'Talharim (Espinafre)',
        size: '250g, 500g, 1kg',
        description:
          'Massa fresca tipo talharim com sabor espinafre, com trigo e ovos caipiras',
        price: '36 / kg',
        picture: 'talharim-esp.jpg',
      },
      {
        name: 'Penne',
        size: '500g, 1kg',
        description:
          'Massa fresca tradicional tipo penne, com trigo e ovos caipiras',
        price: '36 / kg',
        picture: 'penne.jpeg',
      },
      {
        name: 'Conchiglione',
        size: '500g, 1kg',
        description:
          'Massa fresca tradicional tipo conchiglione, com trigo e ovos caipiras',
        price: '36 / kg',
        picture: 'conchiglione.jpeg',
      },
      {
        name: 'Spatzle',
        size: '500g, 1kg',
        description:
          'Pedacinhos de massa cozido, deliciosa massa tradicional da Alemanha',
        price: '36 / kg',
        picture: 'spatzle.jpeg',
      },
      {
        name: 'Nhoque',
        size: '500g, 1kg',
        description:
          'Massa tradicional tipo nhoque, com batata asterix e ovos caipiras',
        price: '40 / kg',
        picture: 'nhoque.jpg',
      },
    ],
  },
  {
    name: 'Recheadas',
    items: [
      {
        name: 'Capeletti de Frango',
        size: '500g, 1kg',
        description: 'Chapeuzinhos de massa recheados com frango',
        price: '56 / kg',
        picture: 'capeleti.jpg',
      },
      {
        name: 'Ravioli de Nozes',
        size: '20 unidades',
        description:
          'Pasteizinhos de massa tradicional recheados com mussarela, nozes, requeijão e manjericão',
        price: 35,
        picture: 'ravioli-2.jpg',
      },
      {
        name: 'Ravioli de Gorgonzola',
        size: '20 unidades',
        description:
          'Pasteizinhos de massa tradicional recheados com mussarela, requeijão e gorgonzola',
        price: 35,
        picture: 'ravioli-1.jpg',
      },
      {
        name: 'Canelone de Presunto e Queijo',
        size: '6 unidades',
        description:
          'Rolos de massa recehados com presunto e queijo e molho de tomate caseiro',
        price: 35,
        picture: 'caneloni.jpg',
      },
    ],
  },
  // {
  //   name: 'Lasanhas',
  //   items: [
  //     {
  //       name: 'Lasanha de Ragú',
  //       size: '500g, 1kg',
  //       description:
  //         'Lasanha com molho de tomate, linguiça de porco e carne de boi',
  //       price: '34 / kg',
  //       picture: 'lasanha.jpg',
  //       unavaliable: true
  //     },
  //     {
  //       name: 'Lasanha de Frango',
  //       size: '500g, 1kg',
  //       description: 'Lasanha com molho bechamel e frango desfiado',
  //       price: '34 / kg',
  //       picture: 'lasanha-frango.jpeg',
  //       unavaliable: true
  //     },
  //   ],
  // },
  {
    name: 'Molhos',
    items: [
      {
        name: 'Manteiga de Ervas',
        size: '50g',
        description: 'Manteiga com alho e ervas da horta La Farina',
        price: 6,
        picture: 'manteiga.jpeg',
      },
      {
        name: 'Molho de Tomate',
        size: '200g',
        description: 'Molho caseiro de tomates com alho e manjericão',
        price: 12,
        picture: 'molho-tomate.jpeg',
      },
      {
        name: 'Caldo para Capeletti',
        size: '300g',
        description: 'Caldo de frango com alho, cenoura, salsa e cebolinha',
        price: 6,
        picture: 'caldo-capeletti.jpeg',
      },
    ],
  },
]
