import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaWhatsapp, FaInstagram, FaTimes } from 'react-icons/fa'

import logo from '../assets/la-farina-logo.png'
import ravioli from '../assets/ravioli.png'
import embalagem from '../assets/embalagem.jpg'
import { cardapio } from '../cardapio'

import '../App.css'

const MenuItem = ({ item }) => (
  <div className={item.unavaliable ? "row menu-item mx-0 py-5 unavaliable-overlay" : "row menu-item mx-0 py-5"}>
    <div className="col-8" style={{ minHeight: 210 }}>
      <h3 className="text-3xl pb-2">{item.name}</h3>
      <p className="pb-2">R$ {item.price}</p>
      <p className="text-muted">
        {item.description} ({item.size})
      </p>
    </div>
    <div className="col-4">
      {item.picture ? (
        <img
          className="img-fluid shadow rounded"
          src={require(`../assets/${item.picture}`)}
          alt={item.name}
        ></img>
      ) : (
        <div style={{ width: '100%' }}></div>
      )}
    </div>
  </div>
)

export function Home() {
  const [selectedTab, setSelectedTab] = useState(cardapio[0].name)
  const [showBanner, setShowBanner] = useState(false)

  return (
    <div>
      {/* La Farina Recebe Banner */}
      {showBanner ? (
        <div className="banner">
          <Link to="recebe">
            <div className="text-white">
              Temos novidades! Apresentamos o ✨{' '}
              <b>
                <i>La Farina Recebe</i>
              </b>{' '}
              ✨ Clique aqui para saber mais!
            </div>
          </Link>
          <span style={{ margin: '0px auto' }}></span>
          <button
            type="button"
            className="btn text-white"
            onClick={() => setShowBanner(false)}
          >
            <FaTimes size="1.4em" />
          </button>
        </div>
      ) : null}

      {/* Header */}
      <header>
        <nav className="navbar p-4">
          <a className="navbar-brand" href="/">
            <img
              className="img-fluid logo"
              src={logo}
              alt="La Farina logo"
            ></img>
          </a>
          <a href="#pecaja" className="text-body mt-2 text-3xl">
            <h4>Peça já!</h4>
          </a>
        </nav>
      </header>

      <main>
        {/* Hero */}
        <section id="hero">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 px-3">
                <h2 className="hero-text">Massas tradicionais desde 2016.</h2>
              </div>
              <div className="col-12 col-md-6">
                <img className="img-fluid" src={ravioli} alt="Ravioli" />
              </div>
            </div>
          </div>
        </section>

        {/* Menu */}
        <section id="#cardapio" className="container px-4 cardapio">
          {/* Tabs */}
          <div className="row pt-4 px-3">
            {cardapio.map((c) => {
              const isSelected = c.name === selectedTab
              return (
                <div className="col-6 col-sm-4 col-md-3 pl-1">
                  <button
                    className={`mt-4 border-black border w-full text-center ${isSelected && 'tab-selected'
                      }`}
                    onClick={() => setSelectedTab(c.name)}
                  >
                    <h4 className="m-0 p-2 text-2xl">
                      {c.name} <sup>{c.items.length}</sup>
                    </h4>
                  </button>
                </div>
              )
            })}
          </div>

          {/* Items */}
          <div className="row">
            {cardapio
              .find((c) => c.name === selectedTab)
              .items.map((item) => (
                <div className="col-12 col-md-6 px-0">
                  <MenuItem item={item}></MenuItem>
                </div>
              ))}
          </div>
        </section>
        <section id="pecaja">
          <div className="pecaja pt-5 container">
            <div className="row">
              <div className="col-12 col-md-7 p-5">
                <h1 className="display-4 pb-3 text-5xl">Peça já!</h1>
                <p>
                  Entregas todas as <strong>Sextas-feiras</strong> em Vitória,
                  Vila Velha, Domingos Martins e região. <br /> <br /> Faça sua
                  encomenda através do WhatsApp ou Instagram.
                </p>
                <a
                  href="https://wa.me/5527999430406"
                  className="btn btn-lg btn-whatsapp mt-3 mr-3 shadow py-2 rounded-0"
                >
                  <div className="flex items-center">
                    <FaWhatsapp className="icon mr-2" />
                    WhatsApp
                  </div>
                </a>
                <a
                  href="https://instagram.com/lafarina.massas"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-lg btn-instagram mt-3 mr-3 shadow rounded-0"
                >
                  <div className="flex items-center">
                    <FaInstagram className="icon mr-2" />
                    Instagram
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-5 p-5">
                <img
                  src={embalagem}
                  className="img-fluid shadow rounded"
                  alt="Talharim"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="pt-4 pb-3 px-4 text-center">
          <h5>
            <strong>La Farina</strong>. Feito com ♥ em Domingos Martins -
            Espírito Santo.
          </h5>
        </div>
      </footer>
    </div>
  )
}
