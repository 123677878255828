import React from 'react'
import { Route, Routes } from 'react-router-dom'

import './App.css'

import { Home } from './pages/Home'
import { Recebe } from './pages/Recebe'
import { RecebeFotos } from './pages/RecebeFotos'


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/recebe" element={<Recebe />} />
      <Route path="/recebe-fotos" element={<RecebeFotos />} />
    </Routes>
  )
}

export default App
